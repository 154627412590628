import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/bugsnag.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Bugsnag?</SubTitle>
      <p>
        Bugsnag is a professional tool to monitor the stability of your app and report errors.
        Connecting LiveSession with Bugsnag comes with a range of unique benefits, such as:
      </p>
      <ul>
        <li>Direct access to session recordings from Bugsnag dashboard</li>
        <li>Access to all information in one place</li>
        <li>Ability to see where the error has occurred</li>
      </ul>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        With this integration, every new event in Bugsnag gets an extra tab called <i>sessionURL</i>
        . The tab contains a <i>url label</i> with a link to the session recording.
      </p>
      <p>
        Every time a new session will start, our code will send an event to Segment with a link to
        session replay.
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important</strong>: Remember that LiveSession only starts recording if it detects
        activity of user (
        <a
          href="https://developers.livesession.io/javascript-api/recording-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          find out more
        </a>{' '}
        informations about recording conditions). Because of this, Bugsnag will wait 3 seconds after
        initializing. If the user doesn’t do anything for more than 3 seconds, the <i>sessionURL</i>{' '}
        tag will be empty.
      </div>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script type="text/javascript">
var createdSessionURL = "";
Bugsnag.start({
    apiKey: "YOUR_API_KEY",
    onError: function (event) {
        __ls("getSessionURL", function (url, isNewSession) {
        createdSessionURL = url;
        });
        var wait = function wait(ms) {
        return new Promise(function (r, j) {
            return setTimeout(r, ms);
        });
        };

        return wait(3000).then(function () {
        event._metadata.sessionURL.url = createdSessionURL;
        return event;
        });
    },
});
Bugsnag.addMetadata("sessionURL", {
url: createdSessionURL,
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to LiveSession installed on your website. If you
        haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Bugsnag integration',
  canonical: '/help/bugsnag-integration/',
  metaDescription: 'Add LiveSession recordings to issues in Bugsnag.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
